import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "../../actions/auth";
import { getConferances } from "../../actions/conferance";
import PropTypes from "prop-types";

const Register = ({
  setAlert,
  register,
  isAuthenticated,
  getConferances,
  conferances: { conferances, loading },
}) => {
  useEffect(() => {
    getConferances();
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    rolls: "",
    Conferance: "",
  });

  const { name, email, password, password2, rolls, Conferance } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      console.log("كلمة المرور غير متطابقة");
    } else {
      register({ name, email, password, rolls, Conferance });
      console.log("تم عملية انشاء الحساب بنجاح");

      setFormData({
        name: "",
        email: "",
        password: "",
        password2: "",
        rolls: "",
        theHub: "",
      });
    }
  };

  // if (isAuthenticated) {
  //   return <Redirect to='/dashboard' />;
  // }

  return (
    <Fragment>
      <div className="card p-2 m-3">
        <div className="card-content center m-2">
          <h1 className="large text-center">اضافة مسؤول</h1>
          <p className="lead text-center">
            <i className="fas fa-user" /> تكوين حساب للاحد أعضاء اللجنة
          </p>

          <form
            className="form"
            onSubmit={(e) => onSubmit(e)}
            style={{ direction: "rtl" }}
          >
            <div className="form-group">
              <input
                type="text"
                placeholder="اسم المسؤول"
                name="name"
                value={name}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="البريد الالكتروني"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
              />
              <small className="form-text">
                الرجاء التأكد من صحة البريد الالكتروني
              </small>
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="كلمة المرور "
                name="password"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="تأكيد كلمة المرور"
                name="password2"
                value={password2}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div class="input-field ">
              <label>صلاحيات المسؤولين</label>
              <select
                type="number"
                name="rolls"
                value={rolls}
                onChange={(e) => onChange(e)}
              >
                <option value="0" selected>
                  اختر صلاحيات المسؤول
                </option>
                <option value="2">مسؤول اول</option>
                <option value="1">مسؤول تاني</option>
                <option value="0">مسؤول ثالث</option>
              </select>
            </div>

            <div class="input-field ">
              <label>المؤتمرات</label>
              <select
                name="Conferance"
                value={Conferance}
                onChange={(e) => onChange(e)}
              >
                <option value="" selected>
                  اختر المؤتمر
                </option>
                {conferances &&
                  conferances.map((conferance) => (
                    <option value={conferance._id}>{conferance.title}</option>
                  ))}
              </select>
            </div>
            <input
              type="submit"
              className="btn btn-primary btn-block lead"
              value="تسجيل"
            />
          </form>
        </div>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  conferances: state.conferances,
});

export default connect(mapStateToProps, { register, getConferances })(Register);
