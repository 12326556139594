import React, { Fragment, useEffect } from "react";
import Research from "../components/research/Researchs";

const ResearchPage = () => {
  return (
    <Fragment>
      {/* <!-- Section: Users --> */}
      <section className="section section-users">
        <Research />
      </section>

      {/* <!-- Footer --> */}
      <footer className="section bg-dark center-align footer">
        <p> قسم الموقع الالكتروني &copy; 2020</p>
      </footer>
    </Fragment>
  );
};

export default ResearchPage;
