import React, { Fragment, useEffect } from "react";
import HomePoge from "./pages/ResearchPage";
import Conferances from "./pages/conferances.page";
import ChangeStatus from "./pages/change";
import NavBar from "./components/Layout/NavBar";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Register";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/routing/PrivateRoute";
import SuperPrivateRoute from "./components/routing/SuperPrivateRoute";
// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Fragment>
        <Router>
          <NavBar />
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/" exact component={HomePoge} />
            <PrivateRoute path="/conferances" exact component={Conferances} />
            <SuperPrivateRoute exact path="/signup" component={Signup} />
            <Route path="/:id" exact component={ChangeStatus} />
          </Switch>
        </Router>
      </Fragment>
    </Provider>
  );
};

export default App;
