import React, { Fragment, useEffect } from "react";
import Research from "../modals/status";
import { getResearchs, getResearch } from "../actions/research";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Back } from "../components/research/Language";

const ResearchPage = ({
  match,
  getResearch,
  researchs: { researchs, research, loading, lang },
}) => {
  useEffect(() => {
    getResearch(match.params.id);
  }, [research]);
  return (
    <Fragment>
      {/* <!-- Section: Users --> */}
      <section className="section section-users">
        <div className="container">
          <Link to="/" className="btn btn-dark">
            {Back[lang]}
          </Link>
        </div>
        {research && <Research research={research} />}
      </section>

      {/* <!-- Footer --> */}
      <footer className="section bg-dark center-align footer">
        <p> قسم الموقع الالكتروني &copy; 2020</p>
      </footer>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  researchs: state.researchs,
});

export default connect(mapStateToProps, { getResearch })(ResearchPage);
