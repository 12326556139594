import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";

const NavBar = ({ user, logout, isAuthenticated, lang }) => {
  return (
    <Fragment>
      {/* <nav className="blue darken-2" style={{ direction: "rtl" }}>
        <div className="container">
          <div className="nav-wrapper">
            <Link to="/" className="brand-logo">
              لوحة التحكم للمؤتمر العلمي
            </Link>

            <ul className="left hide-on-med-and-down">
              {isAuthenticated && (
                <li>
                  <a
                    className="dropdown-trigger dropdown-navbar"
                    href="#!"
                    data-target="dropdown1"
                  >
                    {user.name}
                    <i className="material-icons right">arrow_drop_down</i>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <ul
        id="dropdown1"
        className="dropdown-content"
        style={{ paddingRight: "0", textAlign: "left" }}
      >
        {isAuthenticated && user.rolls === 2 ? (
          <li style={{ paddingRight: "0" }}>
            <Link
              to="/signup"
              className="right-align"
              style={{ paddingLeft: "50px" }}
            >
              <i className="material-icons">add_circle</i>
              تسجيل عضو
            </Link>
          </li>
        ) : null}
        <li className="divider" style={{ paddingRight: "0" }}></li>
        <li style={{ paddingRight: "0" }}>
          <a style={{ paddingLeft: "50px" }} href="#!" onClick={() => logout()}>
            <i className="material-icons">directions_run</i>
            تسجيل خروج
          </a>
        </li>
      </ul> */}

      <div
        className="navbar bg-dark"
        style={{ direction: lang === "en" ? "ltr" : null }}
      >
        <a className="brand-logo">
          {lang === "ar" ? "لوحة التحكم للمؤتمر العلمي" : "Control Panel"}
        </a>
        <ul>
          {isAuthenticated && (
            <Fragment>
              <li>
                <a
                  className="dropdown-trigger dropdown-navbar"
                  href="#!"
                  data-target="dropdown1"
                >
                  {user.name}
                </a>
              </li>
              {user.rolls === 2 && (
                <Fragment>
                  <li>
                    <Link
                      to="/signup"
                      className="dropdown-trigger dropdown-navbar"
                      href="#!"
                      data-target="dropdown1"
                    >
                      اضافة مسؤول
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/conferances"
                      className="dropdown-trigger dropdown-navbar"
                      href="#!"
                      data-target="dropdown1"
                    >
                      المؤتمرات العلمية
                    </Link>
                  </li>
                </Fragment>
              )}
              <li>
                <a
                  style={{ paddingLeft: "50px" }}
                  href="#!"
                  onClick={() => logout()}
                >
                  <FontAwesomeIcon icon={faRunning} />{" "}
                  {lang === "ar" ? "تسجيل خروج" : "Logout"}
                </a>
              </li>
            </Fragment>
          )}
        </ul>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  lang: state.researchs.lang,
});

export default connect(mapStateToProps, { logout })(NavBar);
