import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addConferance } from "../../actions/conferance";
import "./bootstrap.css";

const AddConferance = ({ addConferance }) => {
  const [addModals, setShow] = useState(false);

  const [conferance, setConferance] = useState({
    Cid: "",
    title: "",
    ConferenceDate: "",
    about: "",
    Description: "",
    deadlineForReceiving: "",
    theHub: "",
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const {
    Cid,
    title,
    ConferenceDate,
    about,
    Description,
    deadlineForReceiving,
    theHub,
  } = conferance;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files);
  };

  const onChange = (e) => {
    setConferance({ ...conferance, [e.target.name]: e.target.value });
    console.log(conferance);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    addConferance(
      file,
      Cid,
      title,
      ConferenceDate,
      about,
      Description,
      deadlineForReceiving,
      theHub
    );
  };

  return (
    <Fragment>
      <a
        href="#!"
        onClick={handleShow}
        className=" btn-outline btn-block btn-radius lead"
      >
        {" "}
        <FontAwesomeIcon icon={faPlus} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> اضافة مؤتمر </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <label htmlFor="file"> ادخل ملفات المؤتمر</label>
            <br />
            <input id="file" type="file" onChange={onChangefile} multiple />
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان المؤتمر"
              required
            />
            <FormInput
              name="Cid"
              type="text"
              handleChange={onChange}
              value={Cid}
              label=" رمز المؤتمر باللغة الانجليزية"
              required
            />
            <FormInput
              name="ConferenceDate"
              type="date"
              handleChange={onChange}
              value={ConferenceDate}
              label="تاريخ الانعقاد"
              required
            />
            <FormInput
              name="deadlineForReceiving"
              type="date"
              handleChange={onChange}
              value={deadlineForReceiving}
              label="اخر موعد لاستقبال البحوث"
              required
            />

            <FormInput
              name="about"
              type="text"
              handleChange={onChange}
              value={about}
              label=" التمهيد"
              required
            />
            <label htmlFor="firstName" className="active">
              عن المؤتمر
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={Description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setConferance({ ...conferance, Description: data });
              }}
            />
            <FormInput
              name="theHub"
              type="text"
              handleChange={onChange}
              value={theHub}
              label=" محاور المؤتمر"
              required
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            اضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  addConferance,
})(AddConferance);
