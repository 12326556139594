import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import DeleteSubject from "../model/DeleteConferance";
import EditConferance from "../model/EditConferance";

const ConferanceItem = ({ conferance }) => {
  const { _id, title, Cid, about, ConferenceDate } = conferance;

  return (
    <tr>
      <td className="center-align">{title}</td>
      <td className="center-align">{Cid}</td>
      <td className="center-align">{about}</td>
      <td className="center-align">{ConferenceDate}</td>
      <td className="center-align">
        <EditConferance correntConferance={conferance} />
        <DeleteSubject id={_id} />
      </td>
    </tr>
  );
};

ConferanceItem.propTypes = {
  conferance: PropTypes.object.isRequired,
};

export default connect(null)(ConferanceItem);
