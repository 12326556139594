import axios from "axios";
import {
  GET_CONFERANCE,
  GET_CONFERANCES,
  ADD_CONFERANCE,
  UPDATE_CONFERANCE,
  DELETE_CONFERANCE,
  ERROR_CONFERANCE,
} from "./types";

// GET CONFERANCES
export const getConferances = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/conferance");
    dispatch({
      type: GET_CONFERANCES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CONFERANCE,
      payload: { msg: error.response, status: error.response.status },
    });
  }
};

export const addConferance = (
  file,
  Cid,
  title,
  ConferenceDate,
  about,
  Description,
  deadlineForReceiving,
  theHub
) => async (dispatch) => {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append("conferanceFile", file[i]);
  }
  formData.append("Cid", Cid);
  formData.append("title", title);
  formData.append("ConferenceDate", ConferenceDate);
  formData.append("about", about);
  formData.append("Description", Description);
  formData.append("deadlineForReceiving", deadlineForReceiving);
  formData.append("theHubSource", theHub);

  try {
    const res = await axios.post("/api/conferance", formData);
    dispatch({
      type: ADD_CONFERANCE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CONFERANCE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateConferance = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/conferance/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_CONFERANCE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CONFERANCE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteConferance = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/conferance/${id}`);
    dispatch({
      type: DELETE_CONFERANCE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_CONFERANCE,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
