import {
  GET_RESEARCHS,
  GET_RESEARCH,
  RESEARCH_ERROR,
  ADD_RESEARCH,
  UPDATE_RESEARCH,
  GET_REVIEWER,
  FILTER_RESEARCH,
  CLEAR_FILTER,
  FILTERING_RESEARCH,
  CHANGE_LANG,
} from "../actions/types";

const initialState = {
  researchs: [],
  research: null,
  filtered: [],
  lang: "ar",
  reviewers: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RESEARCHS:
      return {
        ...state,
        researchs: payload,
        loading: false,
      };
    case GET_RESEARCH:
      return {
        ...state,
        research: state.researchs.find((research) => research._id === payload),
        loading: false,
      };
    case FILTERING_RESEARCH:
      return {
        ...state,
        filtered: state.researchs.filter((research) => {
          // const status = action.payload.status
          //   ? new RegExp(`${action.payload.status}`, "gi")
          //   : "not";
          // // const reve = new RegExp(`${action.payload.filters.Reviewer}`, "gi");
          // const pstatus = action.payload.paperStatus
          //   ? new RegExp(`${action.payload.paperStatus}`, "gi")
          //   : "not";
          return (
            research.status === action.payload.filters.status ||
            research.paperStatus === action.payload.filters.paperStatus ||
            (action.payload.filters.Reviewer === "accepted" &&
              research.Reviewer &&
              research.Reviewer !== "") ||
            (action.payload.filters.Reviewer === "no" && !research.Reviewer)
          );
        }),
      };
    case FILTER_RESEARCH:
      return {
        ...state,
        filtered: state.researchs.filter((research) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            research.researchName.match(regex) ||
            research.theHub.match(regex) ||
            research.author.match(regex) ||
            research.country.match(regex)
          );
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case ADD_RESEARCH:
      return {
        ...state,
        researchs: [payload, ...state.researchs],
        loading: false,
      };
    case CHANGE_LANG:
      return {
        ...state,
        lang: payload,
      };
    case UPDATE_RESEARCH:
      return {
        ...state,
        researchs: state.researchs.map((research) =>
          research._id === action.payload._id ? action.payload : research
        ),
        research: action.payload,
        loading: false,
      };
    case GET_REVIEWER:
      return {
        ...state,
        reviewers: payload,
      };
    case RESEARCH_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
