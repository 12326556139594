import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { changeStatus } from "../actions/research";
import {
  Abst,
  AbstStatus,
  ChangeStatus,
  AcceptedAbst,
  RejectedAbst,
  AbstractNotice,
  ResearchPaper,
  PaperStatus,
  AcceptedPaper,
  RejectedPaper,
  PapertNotice,
  ResearchPaperL,
  accpA,
  rejeA,
  accpP,
  rejeP,
  Back,
  Send,
} from "../components/research/Language";

const AddStatus = ({ changeStatus, research, lang }) => {
  const [Status, setStatus] = useState({
    status: "",
    Notes: "",
  });

  const { status, Notes } = Status;

  const onSubmit = () => {
    changeStatus(status, Notes, research._id);
  };
  const onChange = (e) => {
    setStatus({ ...Status, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      {/* محتوي المودل */}
      <div
        className=" container "
        style={{ direction: lang === "en" ? "ltr" : null }}
      >
        <div className=" card bg-light">
          <h2>{research.researchName}</h2>
          <div className="card ">
            <h3 className="my-1">{Abst[lang]} </h3>
            <Fragment>
              {research.paper && research.paper !== "" ? null : (
                <div
                  dangerouslySetInnerHTML={{ __html: ResearchPaperL[lang] }}
                ></div>
              )}
              <br />
              <label>{PaperStatus[lang]}</label>
              <select
                disabled={
                  research.paper && research.paper !== "" ? false : true
                }
                name="status"
                value={status}
                onChange={(e) => onChange(e)}
              >
                <option value="" selected>
                  {changeStatus[lang]}
                </option>
                <option value="accepted">{AcceptedPaper[lang]}</option>
                <option value="rejected">{RejectedPaper[lang]}</option>
              </select>

              <label htmlFor="firstName" className="active">
                {PapertNotice[lang]}
              </label>
              <textarea
                disabled={
                  research.paper && research.paper !== "" ? false : true
                }
                rows="8"
                cols="50"
                name="Notes"
                value={Notes}
                onChange={(e) => onChange(e)}
              ></textarea>
            </Fragment>
          </div>
        </div>
        <div className="modal-footer">
          <a
            href="#!"
            onClick={onSubmit}
            className="modal-close btn btn-primary"
          >
            {Send[lang]}
          </a>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  lang: state.researchs.lang,
});

export default connect(mapStateToProps, { changeStatus })(AddStatus);
