import React, { useRef, useEffect } from "react";
import { filterResearch, clearFilter } from "../../actions/research";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const ContactFilter = ({
  filterResearch,
  clearFilter,
  researchs: { filtered },
}) => {
  useEffect(() => {
    if (filtered === null) {
      text.current.value = "";
    }
  });
  const text = useRef("");

  const onChange = (e) => {
    filterResearch(e.target.value);
  };

  return (
    <form>
      <input
        ref={text}
        type="text"
        placeholder="ادخل عنوان الورقة او اسم الباحث او محور الورقة او الدولة......."
        onChange={onChange}
      />
    </form>
  );
};
ContactFilter.propTypes = {
  filterResearch: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  researchs: state.researchs,
});

export default connect(mapStateToProps, { filterResearch, clearFilter })(
  ContactFilter
);
