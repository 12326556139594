import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getConferances } from "../actions/conferance";
import AddConferance from "../components/model/AddConferance";
import ConferanceItems from "../components/conferance/ConferanceItems";
const ConferancesPage = ({
  getConferances,
  conferances: { conferances, loading },
  isAuthenticated,
  user: { _id, rolls },
}) => {
  useEffect(() => {
    getConferances();
  }, [loading]);
  return (
    <Fragment>
      <div className="card text-center">
        <h1> المؤتمرات العلمية</h1>
      </div>

      <div className="card">
        <AddConferance />
        <table className="striped">
          <thead>
            <tr>
              <th className="center-align">عنوان المؤتمر</th>
              <th className="center-align"> رمز المؤتمر </th>
              <th className="center-align"> عن المؤتمر</th>
              <th className="center-align"> تاريخ انعقاد المؤتمر</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {conferances.map((conferance) => (
              <Fragment>
                {isAuthenticated && rolls === 2 && (
                  <ConferanceItems conferance={conferance} />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  conferances: state.conferances,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getConferances })(ConferancesPage);
