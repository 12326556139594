import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { addReviewer } from "../../actions/research";

const AddReviewer = ({
  addReviewer,
  research: { _id, researchName },
  reviewers,
}) => {
  const [addModals, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [Reviewer, setReviewer] = useState({
    id: "",
    name: "",
    email: "",
  });
  const { name, email } = Reviewer;

  let revs = reviewers.filter((reviewer) => reviewer.rolls === 0);

  const onChange = async (e) => {
    let reviewer = await reviewers.find((r) => r._id === e.target.value);
    setReviewer({
      id: reviewer._id,
      name: reviewer.name,
      email: reviewer.email,
    });
  };
  const { id } = Reviewer;

  const onSubmit = () => {
    if (id === "") {
      console.log("لم المراجع غير موجود  ");
    } else {
      addReviewer(Reviewer, _id);
      setShow(false);
    }
  };
  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a className="btn bg-dark" onClick={handleShow}>
        {" "}
        اضافة مراجع
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h2 className="my-3">{researchName}</h2>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <main>
            <div>
              <select onChange={(e) => onChange(e)}>
                <option value="">اختر المراجع</option>

                {revs.map((r) => (
                  <option value={r._id}>{r.name}</option>
                ))}
              </select>
            </div>
          </main>
          <Modal.Footer>
            <div style={{ marginTop: "15%" }} className="right-align">
              <button
                className="modal-close bg-primary  btn"
                onClick={handleClose}
              >
                اغلاق
              </button>
              <a
                href="#!"
                onClick={onSubmit}
                className="modal-close bg-primary  btn"
              >
                ارسال
              </a>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  reviewers: state.researchs.reviewers,
});

export default connect(mapStateToProps, { addReviewer })(AddReviewer);
