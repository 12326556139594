import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import Details from "../../components/model/details";
import Status from "../../modals/status";
import AddReviewer from "../../components/model/Reviewer";
import { accpA, rejeA, accpP, rejeP, ChangeS } from "./Language";

// import EditProductModal from "../models/Edit/EditProductModal";

const ResearchItem = ({ research, lang, i }) => {
  const {
    _id,
    researchName,
    theHub,
    author,
    country,
    paper,
    Organization,
    status,
    paperStatus,
    Reviewer,
  } = research;

  return (
    <tr>
      <td className="center-align">{i}</td>
      <td>
        {paper && paper !== "" && (
          <a href={paper} style={{ fontSize: "1.5rem" }}>
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )}{" "}
      </td>
      <td className="center-align">{researchName}</td>
      <td className="center-align">{author}</td>
      <td className="center-align">{theHub}</td>
      <td className="center-align">{country}</td>
      <td className="center-align">
        {status && status !== "" ? (
          <p
            className={
              status === "accepted"
                ? "max-content text-success"
                : "max-content text-danger"
            }
          >
            {" "}
            <p>{status === "accepted" ? accpA[lang] : rejeA[lang]} </p>
          </p>
        ) : (
          <p>لم يتم مراجعة الملخص</p>
        )}

        {paperStatus && paperStatus !== "" ? (
          <p
            className={
              paperStatus === "accepted"
                ? "max-content text-success"
                : "max-content text-danger"
            }
          >
            {" "}
            <p>{paperStatus === "accepted" ? accpP[lang] : rejeP[lang]} </p>
          </p>
        ) : (
          <p className="max-content">لم يتم مراجعة الورقة</p>
        )}
      </td>

      <td className="center-align">
        <Details research={research} lang={lang} />
        {Reviewer && Reviewer !== "" ? (
          <p>{Reviewer.name}</p>
        ) : (
          <AddReviewer research={research} />
        )}

        {research.status &&
        research.status !== "" &&
        research.paperStatus &&
        research.paperStatus !== "" ? null : (
          <Link className="btn btn-dark" to={`/${_id}`}>
            {ChangeS[lang]}
          </Link>
        )}

        {/* <a href={`#${_id}`} className="btn bg-dark modal-trigger">
          ن.الملخص
        </a>
        <a href={`#images-${_id}`} className="btn bg-dark modal-trigger">
          ن.الورقة
        </a>
        <a className="btn bg-dark">حدف</a> */}
      </td>

      {/* <!-- Modal Details --> */}

      {/* <Status research={research} />
      <AddReviewer research={research} /> */}
    </tr>
  );
};

ResearchItem.propTypes = {
  research: PropTypes.object.isRequired,
};

export default connect(null)(ResearchItem);
