import axios from "axios";
import {
  GET_RESEARCHS,
  GET_RESEARCH,
  RESEARCH_ERROR,
  ADD_RESEARCH,
  UPDATE_RESEARCH,
  FILTER_RESEARCH,
  FILTERING_RESEARCH,
  CLEAR_FILTER,
  GET_REVIEWER,
  CHANGE_LANG,
} from "./types";

// Get Researchs
export const getResearchs = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`http://localhost:3034/api/researchs/${id}`);

    dispatch({
      type: GET_RESEARCHS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Get one Research
export const getResearch = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RESEARCH,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get User
export const getUsers = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `http://localhost:3034/api/researchs/reviewers/${id}`
    );

    dispatch({
      type: GET_REVIEWER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// filter Research
export const filterResearch = (text) => (dispatch) => {
  dispatch({ type: FILTER_RESEARCH, payload: text });
};

// filter Research by radios
export const filtering = (filter) => (dispatch) => {
  dispatch({ type: FILTERING_RESEARCH, payload: filter });
};

//clear filter
export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER });
};

// تغيير الحالة
export const changeStatus = (status, Notes, _id) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `http://localhost:3034/api/researchs/${_id}/status`,
      { status, Notes },
      config
    );

    dispatch({
      type: UPDATE_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

//  اضافة مراجع
export const addReviewer = (reviewer, _id) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `http://localhost:3034/api/researchs/${_id}/reviewer`,
      reviewer,
      config
    );

    dispatch({
      type: UPDATE_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const ChangeLang = (lang) => async (dispatch) => {
  dispatch({
    type: CHANGE_LANG,
    payload: lang,
  });
};
