import {
  GET_CONFERANCES,
  GET_CONFERANCE,
  ADD_CONFERANCE,
  UPDATE_CONFERANCE,
  ERROR_CONFERANCE,
  DELETE_CONFERANCE,
} from "../actions/types";

const inialState = {
  conferances: [],
  conferance: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFERANCES:
      return {
        ...state,
        conferances: payload,
        loading: false,
      };
    case GET_CONFERANCE:
      return {
        ...state,
        conferance: payload,
        loading: false,
      };
    case ADD_CONFERANCE:
      return {
        ...state,
        conferances: [...state.conferances, payload],
        loading: false,
      };
    case UPDATE_CONFERANCE:
      return {
        ...state,
        conferances: state.conferances.map((conferance) =>
          conferance._id === payload._id ? payload : conferance
        ),
      };
    case DELETE_CONFERANCE:
      return {
        ...state,
        conferances: state.conferances.filter(
          (conferance) => conferance._id !== payload
        ),
      };
    case ERROR_CONFERANCE:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
