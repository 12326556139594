import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";

const Details = ({
  lang,
  research: {
    _id,
    CV,
    researchName,
    abstruct,
    theHub,
    author,
    email,
    phone,
    country,
    Organization,
    Qualification,
    Degree,
    author1,
    email1,
    author2,
    email2,
    createdAt,
    Notes,
    paperNotes,
  },
}) => {
  const [addModals, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a className="btn bg-dark" onClick={handleShow}>
        {" "}
        التفاصيل
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h5 className="center-align">{researchName}</h5>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ lineHeight: "2.4rem", fontSize: "1.3rem" }}>
            <ul className="right-align">
              <li>
                {" "}
                <span className="flow-text"> الرقم المعرف :</span>
                {_id}
              </li>

              <li>
                {" "}
                <span className="flow-text">المحور /Axis :</span>
                {theHub}
              </li>
              <li>
                <span className="flow-text">اسم الباحث / Name of Author :</span>{" "}
                {author}
              </li>

              <li>
                <span className="flow-text">رقم الهاتف :</span> {phone}
              </li>
              <li>
                <span className="flow-text">البريد الالكتروني :</span> {email}
              </li>

              <li>
                {" "}
                <span className="flow-text">الدولة / Country :</span> {country}
              </li>
              <li>
                <span className="flow-text">
                  الجهة التابع لها / Organization :
                </span>{" "}
                {Organization}
              </li>
              <li>
                {" "}
                <span className="flow-text">
                  المؤهل العلمي / Qualification :
                </span>
                {Qualification}
              </li>
              <li>
                {" "}
                <span className="flow-text">الدرجة العلمية / Degree :</span>
                {Degree}
              </li>
              {author1 && (
                <Fragment>
                  <li>
                    <span className="flow-text">
                      اسم الباحث التاني / Name of Author 2:
                    </span>{" "}
                    {author1}
                  </li>
                  <li>
                    {" "}
                    <span className="flow-text">البريد الالكتروني :</span>{" "}
                    {email1}
                  </li>
                </Fragment>
              )}
              {author2 && (
                <Fragment>
                  <li>
                    {" "}
                    <span className="flow-text">
                      اسم الباحث الثالث / Name of Author 3 :
                    </span>
                    {author2}
                  </li>
                  <li>
                    {" "}
                    <span className="flow-text">البريد الالكتروني :</span>
                    {email2}
                  </li>
                </Fragment>
              )}
              <li>
                {" "}
                <span className="flow-text">الملخص / Abstruct :</span>
              </li>
              <li
                dangerouslySetInnerHTML={{ __html: abstruct }}
                className="text-justify p-2"
              ></li>
              {Notes && Notes !== "" && (
                <Fragment>
                  <h3> ملاحظات المقيم عن الملخص :</h3>
                  <li
                    dangerouslySetInnerHTML={{ __html: Notes }}
                    className="text-justify text-dark p-2"
                  ></li>
                </Fragment>
              )}

              {paperNotes && paperNotes !== "" && (
                <Fragment>
                  <h3>ملاحظات المقيم عن الورقة :</h3>
                  <li
                    dangerouslySetInnerHTML={{ __html: paperNotes }}
                    className="text-justify text-dark p-2"
                  ></li>
                </Fragment>
              )}
            </ul>
          </div>
          <Modal.Footer>
            <button
              className="btn-custom btn-dark center"
              onClick={handleClose}
            >
              اغلاق
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Details;
