import { combineReducers } from "redux";
import auth from "./auth";

import researchs from "./researchReducer";
import conferances from "./conferance.reducers";

export default combineReducers({
  auth,
  researchs,
  conferances,
});
