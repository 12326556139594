import React, { useState } from "react";

const RadioBox = ({ abstra, handleFilters, title }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event) => {
    handleFilters(event.target.value);
    setValue(event.target.value);
  };

  return abstra.map((a, i) => (
    <div key={i}>
      <p className=" clearfix">
        <label>
          <input
            onChange={handleChange}
            value={a.value}
            name={title}
            type="radio"
          />
          <span>{a.name}</span>
        </label>
      </p>
    </div>
  ));
};

export default RadioBox;
