//STATUS BAR
export const NumAbstRecev = {
  ar: "عدد الملخصات المستلمة",
  en: "Received Abstracts",
};
export const NumAbstAccep = {
  ar: "عدد الملخصات المقبولة",
  en: "Accepted Abstracts",
};
export const NumAbstRejc = {
  ar: "عدد الملخصات المرفوضة",
  en: "Rejected Abstracts",
};
export const NumPaperRecev = {
  ar: "عدد الورقات المستلمة",
  en: "Received Papers",
};
export const NumPaperAccep = {
  ar: "عدد الورقات المقبولة",
  en: "Accepted Papers",
};
export const NumPaperRejc = {
  ar: "عدد الورقات المرفوضة",
  en: "Rejected Papers",
};

export const participants = {
  ar: "المشاركين في المؤتمر",
  en: "Conference participants",
};

// table head
export const Papers = {
  ar: "الورقة",
  en: "Paper",
};

export const titlePaper = {
  ar: "عنوان الورقة",
  en: "Title of Paper",
};

export const nameAuthor = {
  ar: "اسم الباحث",
  en: "researcher name",
};

export const Axis = {
  ar: "المحور",
  en: "Axis",
};
export const Country = {
  ar: "الدولة",
  en: "Country",
};
export const statusAbs = {
  ar: "حالة الملخص و الورقة",
  en: "Status of abstract and paper",
};

// buttons
export const preview = {
  ar: "التفاصيل",
  en: "preview",
};
export const ChangeS = {
  ar: "تغير الحالة",
  en: "Change status",
};

export const Close = {
  ar: "اغلاق",
  en: "Close",
};
export const CVV = {
  ar: "السيرة الذاتية",
  en: "CV",
};

export const Back = {
  ar: "رجوع للخلف",
  en: "Back",
};

export const Send = {
  ar: "ارسال",
  en: "Send",
};

//تفاصيل مراجعة الملخص او الورقة
export const Abst = {
  ar: "الملخص",
  en: "Abstract",
};
export const AbstStatus = {
  ar: "حالة الملخص",
  en: "Abstract Status",
};
export const ChangeStatus = {
  ar: "اختر الحالة",
  en: "Select Status",
};
export const AcceptedAbst = {
  ar: "مقبول",
  en: "Accepted",
};
export const RejectedAbst = {
  ar: "مرفوض",
  en: "Rejected",
};

export const AbstractNotice = {
  ar: "ملاحظات المقييم",
  en: "Evaluator notes",
};
export const ResearchPaper = {
  ar: `الورقة البحثية`,
  en: `Research paper`,
};

export const ResearchPaperL = {
  ar: `
  <ul class="text-danger">
  <h4>
  لايمكن تغيير حالة الورقة في حالة :
 </h4>
  <li>اذا كان الملخص غير مقبول او لم يتم مراجعته </li>
  <li>اذا لم يتم رفع الورقة حتي الان</li>
</ul>`,
  en: `
        
        <ul class="text-danger">
        <h4>You can not change the status of the paper in the case of:
        </h4>
            <li>If the abstract is not acceptable or have not been reviewed.</li>
            <li>If the paper is not uploaded yet.</li>
        </ul>`,
};
export const PaperStatus = {
  ar: "حالة الورقة",
  en: "Paper Status",
};

export const AcceptedPaper = {
  ar: "مقبول",
  en: "Accepted",
};
export const RejectedPaper = {
  ar: "مرفوض",
  en: "Rejected",
};

export const PapertNotice = {
  ar: "ملاحظات المقييم",
  en: "Reviewer notes",
};

export const accpA = {
  ar: "الملخص مقبول",
  en: " Abstruct Accepted",
};

export const rejeA = {
  ar: "الملخص مرفوض",
  en: "Abstaruct Rejected",
};

export const accpP = {
  ar: "الورقة البحثية مقبول",
  en: " Paper Accepted",
};
export const rejeP = {
  ar: "الورقة البحثية مرفوض",
  en: "Paper Rejected",
};
