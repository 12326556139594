import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../Layout/Spinner";
import RadioBox from "./RadioBox";
import { getResearchs, getUsers } from "../../actions/research";
import ResearchItems from "./ResearchItems";
import Filter from "./ResearchFilter";
import { filtering, ChangeLang } from "../../actions/research";
import { CSVLink } from "react-csv";
import {
  NumAbstRecev,
  NumAbstAccep,
  NumAbstRejc,
  NumPaperRecev,
  NumPaperAccep,
  NumPaperRejc,
  Papers,
  titlePaper,
  nameAuthor,
  Axis,
  Country,
  statusAbs,
  participants,
} from "./Language";

const Research = ({
  ChangeLang,
  getResearchs,
  getUsers,
  filtering,
  isAuthenticated,
  user: { _id, rolls, Conferance },
  researchs: { researchs, filtered, lang, loading },
}) => {
  useEffect(() => {
    getResearchs(Conferance);
    getUsers(Conferance);
    // eslint-disable-next-line
  }, [loading]);
  const [myFilters, setMyFilters] = useState({
    filters: { Reviewer: "", status: "", paperStatus: "" },
  });
  const [Abstra, setAbstra] = useState([
    { name: "الكل", value: "all" },
    { name: "مقبول", value: "accepted" },
    { name: "مرفوض", value: "rejected" },
  ]);
  const [reviewer, setreviewer] = useState([
    { name: "الكل", value: "all" },
    { name: "نعم", value: "accepted" },
    { name: "لا", value: "no" },
  ]);

  let AcceptedAbstruct = researchs.filter((r) => r.status === "accepted");
  let RejectedAbstruct = researchs.filter((r) => r.status === "rejected");
  let papers = researchs.filter((r) => r.paper && r.paper !== "");
  let i = 0;
  // ************************
  const handleFilters = (filters, filterBy) => {
    const newFilters = { ...myFilters };
    newFilters.filters[filterBy] = filters;

    // getFilteredProducts(skip, limit, newFilters);
    setMyFilters(newFilters);
    filtering(myFilters);
    console.log(myFilters);
  };

  // ************************

  let headers = [
    { label: "الدرجة العلمية", key: "Degree" },
    { label: "المؤهل العلمي", key: "Qualification" },
    { label: "الجهة التابع لها", key: "Organization" },
    { label: "الدولة", key: "country" },
    { label: "المحور", key: "theHub" },
    { label: "عنوان البحث", key: "researchName" },
    { label: " اسم الباحث الاول", key: "author" },
    { label: " ايميل الباحث الاول", key: "email" },
    { label: " اسم الباحث الثاني", key: "author1" },
    { label: " ايميل الباحث الثاني", key: "email1" },
    { label: " اسم الباحث الثالث", key: "author2" },
    { label: " ايميل الباحث الثالث", key: "email2" },
  ];

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <section
        className="section section-posts grey lighten-4"
        style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        <div className="row">
          <div className="col s12">
            <div className="mx-1">
              <a
                href="#!"
                className="btn btn-primary p-2"
                onClick={() => ChangeLang("ar")}
              >
                عربي
              </a>
              <a
                href="#!"
                className="btn btn-primary p-2"
                onClick={() => ChangeLang("en")}
              >
                English
              </a>
            </div>
            <div style={userStyle}>
              <div className="card  bg-primary center-align">
                <h4>{NumPaperRecev[lang]} </h4>
                <h4>{papers.length}</h4>
              </div>
              <div className="card  bg-primary center-align">
                <h4>{NumPaperAccep[lang]}</h4>
                <h4>{AcceptedAbstruct.length}</h4>
              </div>
              <div className="card  bg-dark center-align">
                <h4>{NumPaperRejc[lang]}</h4>
                <h4>{RejectedAbstruct.length}</h4>
              </div>
            </div>
            <div className="card">
              <div className="card-content ">
                {/* ************************ */}
                {isAuthenticated && (rolls === 2 || rolls === 1) && (
                  <Fragment>
                    {" "}
                    <h1>{lang === "ar" ? "بحث" : "Search"}</h1>
                    <div className="card bg-light">
                      <div className="mx">
                        <Filter />
                      </div>
                      <div style={filterStyle}>
                        <div className="card  bg-primary ">
                          <div className="clearfix ">
                            <br />
                            <h3 className="center-align">تم ارساله للمراجع</h3>
                            <hr />

                            {/* //////////////////// */}
                            <div className="">
                              <RadioBox
                                title="rve"
                                abstra={reviewer}
                                handleFilters={(filters) =>
                                  handleFilters(filters, "Reviewer")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card  bg-primary ">
                          <div className="clearfix ">
                            <br />
                            <h3 className="center-align">الورقة</h3>
                            <hr />

                            {/* //////////////////// */}
                            <div className="">
                              <RadioBox
                                title="abs"
                                abstra={Abstra}
                                handleFilters={(filters) =>
                                  handleFilters(filters, "status")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isAuthenticated && rolls === 2 && (
                        <CSVLink
                          className="btn btn-dark"
                          data={filtered}
                          headers={headers}
                          filename={"نتائج-البحث.csv"}
                        >
                          تحميل بيانات البحث
                        </CSVLink>
                      )}
                    </div>
                  </Fragment>
                )}

                <h1>{participants[lang]}</h1>
                <table className="striped">
                  <thead>
                    <tr>
                      <th className="center-align">ر.م</th>
                      <th className="center-align">{Papers[lang]}</th>
                      <th className="center-align"> {titlePaper[lang]}</th>
                      <th className="center-align"> {nameAuthor[lang]}</th>
                      <th className="center-align">{Axis[lang]}</th>
                      <th className="center-align">{Country[lang]}</th>
                      <th className="center-align "> {statusAbs[lang]}</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered !== null && filtered.length !== 0
                      ? filtered.map((research) => (
                          <Fragment key={research._id}>
                            {isAuthenticated && (rolls === 2 || rolls === 1) ? (
                              <ResearchItems
                                research={research}
                                lang={lang}
                                i={(i += 1)}
                              />
                            ) : (
                              rolls === 0 &&
                              research.Reviewer &&
                              research.Reviewer.id === _id && (
                                <ResearchItems
                                  research={research}
                                  key={research._id}
                                  lang={lang}
                                  i={(i += 1)}
                                />
                              )
                            )}
                          </Fragment>
                        ))
                      : researchs.map((research) => (
                          <Fragment key={research._id}>
                            {isAuthenticated && (rolls === 2 || rolls === 1) ? (
                              <ResearchItems
                                research={research}
                                lang={lang}
                                i={(i += 1)}
                              />
                            ) : (
                              rolls === 0 &&
                              research.Reviewer &&
                              research.Reviewer.id === _id && (
                                <ResearchItems
                                  research={research}
                                  key={research._id}
                                  lang={lang}
                                  i={(i += 1)}
                                />
                              )
                            )}
                          </Fragment>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
const userStyle = {
  direction: "rtl",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: "0.2rem",
};
const filterStyle = {
  direction: "rtl",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridGap: "0.7rem",
  fontSize: "1.2rem",
};
Research.propTypes = {
  getResearchs: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  researchs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  researchs: state.researchs,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getResearchs,
  getUsers,
  filtering,
  ChangeLang,
})(Research);
