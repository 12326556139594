import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { deleteConferance } from "../../actions/conferance";

const DeleteConferance = ({ deleteConferance, id }) => {
  const [addModals, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const DeleteC = () => {
    deleteConferance(id);
    setShow(false);
  };
  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a onClick={handleShow}>
        {" "}
        <FontAwesomeIcon icon={faTrashAlt} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> اضافة المادة </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ؟ هل انت متآكد انك تريد حدف هده المادة
          <Modal.Footer>
            <button
              className="btn-custom btn-dark center"
              onClick={handleClose}
            >
              لا
            </button>
            <button className="btn-custom btn-danger center" onClick={DeleteC}>
              نعم
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  deleteConferance,
})(DeleteConferance);
